import React from "react";


const Form = (props) => {

  return (
    <div className="py-10 px-2">
      	
<div class="elfsight-app-4bbe7d39-12b6-4820-ae3b-5944a5c42b38" data-elfsight-app-lazy></div>
    </div >
  );
};

export default Form;


// variable del formulario:
// tname=""
// tlname=""
// temail=""
// tphone=""
// taddress=""
// ttypeservice=""
// tbestway=""
// tcheckphone=""
// tcheckcorreo=""
// tmessage=""
// tbutton=""
// isenglish="true"
