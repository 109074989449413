import React from "react";
import TransparentHeader from "../components/global/TransparentHeader";
// import ContentServices from "../components/Services/ContentServices";
import BaseLayout from "../components/global/BaseLayout";
import ContentMenu from "../components/Services/ContentMenu";

function Services() {
  return (
    <BaseLayout PageName="Menu">
      <div className="md:max-w-full w-full">
        <TransparentHeader
          headertitle="menú"
          Subheader="menú"
        />
        <div className="mainMenu">
          <div className="py-40 w-4/5 mx-auto relative">
            <ContentMenu />
          </div>

        </div>
      </div>
    </BaseLayout>
  );
}

export default Services;
