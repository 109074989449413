import React from "react";

const TransparentHeader = ({ headertitle, bgimg, Subheader }) => {
  return (
    <div
      className="breadcrumb-area"
      style={{ backgroundImage: `url("${bgimg ? bgimg : 'https://firebasestorage.googleapis.com/v0/b/stockredesdirectorios.appspot.com/o/Restaurante%2Fbg-banner-inner-1024x304.png?alt=media&token=44b6b2b7-9e83-483f-b770-ad58500cb002'}")` }}
    >
      <div className="text-center relative">
        <h1 className="text-white px-5 md:px-[20%] pb-5">{headertitle}</h1>
        {
          headertitle === Subheader ?
            <h6 className="text-white">Inicio- {Subheader}</h6>
            :
            <h6 className="text-white">{Subheader}</h6>
        }
      </div>
    </div>
  );
};

export default TransparentHeader;
